@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.scroll-fix {
  webkit-overflow-scrolling: touch;
}

.chrome-picker {
  width: 100% !important;
  border-radius: 8px !important;
  overflow: hidden;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1) !important;
}
.hue-horizontal {
  height: 16px !important;
  cursor: pointer;
  border-radius: 10px;
}
.hue-horizontal > div {
  height: 16px !important;
  width: 16px !important;
}
.hue-horizontal > div > div {
  height: 16px !important;
  width: 16px !important;
  border-radius: 100% !important;
  cursor: pointer;
}
.saturation-white > div > div {
  height: 16px !important;
  width: 16px !important;
  border-radius: 100% !important;
  cursor: pointer;
  border-width: 1px;
  box-shadow: unset !important;
}

.chrome-picker > *:last-child > *:first-child > *:first-child {
  display: none !important;
}

.chrome-picker > *:last-child > *:last-child > *:last-child {
  display: flex !important;
  justify-content: flex-end;
}

.chrome-picker > *:last-child > *:last-child > *:last-child > div {
  margin: 0 !important;
}
